$component: .social-carousel;
$component  {
  position: relative;
  overflow: hidden;

  &__background {
    position: absolute;
    top: spacing(20);
    right: 0;
    left: 0;
    bottom: 0;
  }

  &__frame {
    padding-top: spacing(4);
    padding-bottom: aspect-ratio(5,8);
  }

  &__container {}

  &__header {
    position: relative;
    margin: 0 auto;
    text-align: center;
    max-width: $containerMedium;
    padding: 0 spacing(2.5) spacing(2.5) spacing(2.5);

    @mixin breakpoint $breakpointTabletLandscape {
      padding: 0 spacing(4) spacing(4) spacing(4);
    }
  }

  &__gradient-negative-top-edge,
  &__gradient-top-edge,
  &__gradient-bottom-edge {
    position: absolute;
    width: 100%;
    height: 25%;
    left: 0;
    right: 0;
  }

  &__gradient-negative-top-edge {
    top: -25%;
    background: linear-gradient(to top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
  }

  &__gradient-top-edge {
    top: 0;
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  }

  &__gradient-bottom-edge {
    bottom: 0;
    background: linear-gradient(to top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  }

  &__account {
    width: 100%;
    display: inline-block;
    max-width: $containerXxsmall;

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__content {
    height:0;
    padding-bottom: spacing(40);
    position: relative;
    opacity: 1;

    &.is-loading {
      opacity: 0;
    }

    .juicer-feed {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    .flickity-viewport {
      height: spacing(40);
    }

    .flickity-slider {
      display: flex;
      align-items: center;
    }
  }
}
