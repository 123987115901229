$component: .callout-carousel;
$component  {


  .flickity-viewport {
    /* Note that overflow: hidden is not used because of siteimproves ADA requirements */
    overflow: auto;
    height: 100%;
    @mixin hide-scroll-bars;
  }

  &__frame {
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointTabletLandscape {
      padding-left: spacing(4);
      padding-right: spacing(4);
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-left: spacing(8);
      padding-right: spacing(8);
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
      padding-bottom: spacing(10);
    }
  }

  &__container {
    margin: 0 auto;
    max-width: $containerLarge;
    background-color: $colorWhite;
    position: relative;
    border: 1px solid;

    [data-theme="carillon"] & {
      border-color: $carillonCasper;
    }

    [data-theme="waldhaus"] & {
      border-color: $waldhausYellowMetal;
    }
  }

  &__aspect {
    height: 0;
    padding-bottom: aspect-ratio(2,3);
    padding-bottom: calc(aspect-ratio(2,3) + 320px);
    position: relative;
    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: aspect-ratio(1,2);
    }
  }

  &__carousel {
    white-space: nowrap;
    position: absolute;
    @mixin fill-parent;
    &:focus {
      box-shadow: 0 0 22px $carillonHippieBlue;
    }
  }

  &__card {
    vertical-align: top;
    white-space: normal;
    display: block;
    width: 100%;
  }

  &__controls {
    text-align: center;
  }

  &__dots {
    padding: spacing(2);
    display: inline-block;
    line-height: 1;
  }

  &__dot {
    display: inline-block;
    border: none;
    border-radius: 100%;
    opacity: 0.25;
    cursor: pointer;
    width: spacing(1);
    height: spacing(1);
    padding: 0;
    -webkit-appearance: none;
    margin: 0 2px;

    [data-theme="carillon"] & {
      background-color: $carillonCasper;
    }

    [data-theme="waldhaus"] & {
      background-color: $waldhausYellowMetal;
    }

    &.is-active {
      opacity: 1;
    }
  }
}
