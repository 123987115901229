$component: .feature-block;
$component  {

  &__frame {
    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-bottom: spacing(10);
    }

    @mixin breakpoint $breakpointDesktopXLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
    }
  }

  &--reverse {
    $(component)__container {
      @mixin breakpoint $breakpointTabletLandscape {
        flex-direction: row-reverse;
      }
    }
  }

  &__container {
    margin: 0 auto;
    @mixin breakpoint $breakpointTabletLandscape {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    @mixin breakpoint $breakpointDesktopXLarge {
      max-width: $containerXxlarge;
    }
  }

  &__section {
    width: 100%;
    align-self:center;

    &--media {
      @mixin breakpoint $breakpointTabletLandscape {
        width: 50%;
        width: calc(50% + spacing(4));
      }
      @mixin breakpoint $breakpointDesktop {
        width: calc(50% + spacing(8));
      }
      @mixin breakpoint $breakpointDesktopLarge {
        width: calc(50% + spacing(10));
      }
    }

    &--content {
      padding: spacing(4) spacing(2.5);
      @mixin breakpoint $breakpointTablet {
        padding: spacing(4);
      }
      @mixin breakpoint $breakpointTabletLandscape {
        width: 50%;
        width: calc(50% - spacing(4));
        padding: spacing(4);
      }
      @mixin breakpoint $breakpointDesktop {
        width: calc(50% - spacing(8));
        padding: spacing(8);
      }
      @mixin breakpoint $breakpointDesktopLarge {
        width: calc(50% - spacing(10));
        padding: spacing(10);
      }
    }
  }

  &__media {}

  &__media-link {
    display: block;
  }

  &__content {
    text-align: left;
    @mixin breakpoint $breakpointTabletLandscape {
      max-width: $containerSmall;
    }
  }

  &__title {
    padding-bottom: spacing(2);
  }

  &__subtitle {
    padding-bottom: spacing(2);
    max-width: $containerXsmall;
  }

  &__body {
    padding-bottom: spacing(3);
    @mixin breakpoint $breakpointTablet {
      padding-right: 20%;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      padding-right: 0;
    }
  }

  &__ctas {
    margin-left: spacing(-0.5);
    margin-right: spacing(-0.5);
    padding-top: spacing(2);
  }

  &__cta {
    display: inline-block;
    padding: 0 spacing(0.5) spacing(1) spacing(0.5);
    @mixin uptoBreakpoint $breakpointMobile {
      width: 100%;
    }
  }

  &[data-theme="carillon"] {

    $(component)__title {
      color: $carillonRiverBed;
    }

    $(component)__subtitle,
    $(component)__body {
      color: $carillonDoveGrey;
    }
  }

  &[data-theme="waldhaus"] {

    $(component)__title {
      color: $waldhausYellowMetal;
    }

    $(component)__subtitle,
    $(component)__body {
      color: $waldhausDoveGrey;
    }
  }
}
