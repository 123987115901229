$component: .strip-hero;
$component  {
  background-color: $colorOffWhite;
  margin-bottom: spacing(5);

  @mixin breakpoint $breakpointDesktop {
    margin-bottom: spacing(8);
  }

  @mixin breakpoint $breakpointDesktopLarge {
    margin-bottom: spacing(10);
  }

  &__container {
    position: relative;
    height: 375px;
    @mixin breakpoint $breakpointTablet {
      height: 512px;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      height: 384px;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      height: 600px;
    }
  }

  &__background {
    @mixin fill-parent;
  }

  &__background__media {
    @mixin fill-parent;
  }

  &__foreground {
    @mixin fill-parent;
    background-color: rgba($colorBlack, 0.45);
  }

  &__foreground__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing(2.5);
    height: 100%;
    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }
  }

  &__foreground__icon {
    max-width: spacing(3);
    margin: 0 auto;
    width: 100%;
    padding-bottom: spacing(2);
  }

  &__foreground__headline {
    position: relative;
    max-width: $containerSmall;
    text-align: center;
    color: $colorWhite;
    padding-bottom: spacing(1.7);

    @mixin breakpoint $breakpointTablet {
      max-width: $containerHeadline;
      padding-bottom: spacing(0.5);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(3);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(2);
    }
  }

  &__foreground__subtitle {
    padding-bottom: spacing(2);
  }

  &__foreground__title {
    padding-bottom: spacing(2.8);

    @mixin breakpoint $breakpointTablet {
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(3);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(4);
    }
  }

  /* themes */
  &[data-theme="carillon"] {
    background-color: $carillonHippieBlue;
  }

  &[data-theme="waldhaus"] {
    background-color: $waldhausYellowMetal;
  }
}
