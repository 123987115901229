$component: .billboard-notice;
$component  {
  overflow: hidden;
  position: relative;

  &__frame {
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-bottom: spacing(10);
    }
  }

  &__container {
    height: 100vh;
    min-height: $minBillboardHeight;
    height: calc(100vh - spacing(6));
    @mixin breakpoint $breakpointTablet {
      height: calc(100vh - spacing(8));
    }
    @mixin breakpoint $breakpointTabletLandscape {
      height: calc(100vh - spacing(10) - spacing(4));
    }
  }

  &__container {
    position: relative;
    height: 100vh;
    min-height: $minBillboardHeight;
    background-color: $colorWhite;
  }

  &__background {
    @mixin fill-parent;
  }

  &__background__media {
    @mixin fill-parent;
  }

  &__blend {
    @mixin fill-parent;
    background-color: rgba($colorBlack, 0.25);
  }

  &__gradient {
    @mixin fill-parent;
    background-image: linear-gradient(transparent 0%, rgba($colorWhite, 0) 50%, rgba($colorWhite, 0) 60%, rgba($colorWhite, 0.1) 70%, rgba($colorWhite, 1) 100%);
    padding: spacing(2.5);
    padding-bottom: $offsetMobileActions;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
      padding-bottom: resolve(spacing(4) + $offsetContactBar);
    }
  }

  &__gradient-bottom-edge {
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    position: absolute;
    width: 100%;
    left: 0;
    height: spacing(5);

    @mixin breakpoint $breakpointDesktop {
      height: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      height: spacing(10);
    }
  }

  &__foreground {
    @mixin fill-parent;
  }

  &__foreground__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing(2.5);
    height: 100%;
    text-align: center;
    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }
  }

  &__card {
    background-color: $colorWhite;
    padding: spacing(4);
    max-width: $maxBillboardNoticeWidth;
    width: 100%;
  }

  &__title,
  &__subtitle {

    &:not(:last-child) {
      padding-bottom: spacing(2);
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }
    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }
}
