.social-carousel__content {
}

.j-loading,
.j-paginate {
  display: none !important;
}

.juicer-feed {
  font-family: unset !important;
  .referral {
    display: none !important;
  }
}

.feed-item {
  .j-poster,
  .j-text {
    display: none;
  }
}

.j-overlay {
  display: none !important;
  .j-poster,
  .j-meta,
  .j-bottom,
  .j-navigate {
    display: none !important;
  }
}

.j-overlay {
  padding: spacing(5) 0;
  display: flex;
  align-items: center;

  @mixin breakpoint $breakpointMobile {
    padding: spacing(5) spacing(2.5);
  }

  @mixin breakpoint $breakpointTablet {
    padding: spacing(4);
  }

  .j-post-overlay {
    display: flex;
  }

  .image,
  .j-overlay-text {
    width: 50%;
  }

  .j-overlay-text {
    padding: spacing(2);
    overflow: unset !important;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }
  }

  .j-message {
    margin-bottom: 0 !important;
    text-align: center !important;
    p:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.social-carousel {
  .juicer-feed.modern {
    white-space: nowrap;

    li {
      &.feed-item {
        opacity: 0.5;
        display: inline-block;
        width: 300px;
        vertical-align: middle;
        white-space: normal;
        margin-bottom: 0 !important;
        margin-left: spacing(1);
        margin-right: spacing(1);
        border: none !important;

        @mixin breakpoint $breakpointMobile {
          width: 320px;
        }

        @mixin breakpoint $breakpointTablet {
          margin-left: spacing(2);
          margin-right: spacing(2);
        }

        &.juicer-ad {
          display: none;
        }
      }
    }

    .j-stacker-wrapper {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .j-stacker {
      .j-stack {
          padding-left: 0 !important;
          padding-right: 0 !important;
      }
    }
  }
}
