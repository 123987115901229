$component: .experiences-carousel;
$component  {

  &__frame {
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-bottom: spacing(10);
    }
  }

  &__container {
    margin: 0 auto;
    text-align: center;
  }

  &__header {
    margin-bottom: spacing(2);
    position: relative;
    @mixin breakpoint $breakpointTabletLandscape {
      padding-top: spacing(4);
      padding-bottom: spacing(4);
      margin-bottom: spacing(4);
    }
  }

  &__header-background {
    display: none;
    background-color: $colorOffWhite;
    @mixin fill-parent;

    @mixin breakpoint $breakpointTabletLandscape {
      display: block;
    }
  }

  &__header-background-blend {
    @mixin fill-parent;
    background-color: rgba($colorBlack, 0.45);
  }

  &__header-foreground {
    position: relative;
  }

  &__headline {
    max-width: $containerHeadline;
    text-align: center;
    margin: 0 auto;
    padding: spacing(4) spacing(2.5);

    @mixin breakpoint $breakpointTablet{
      padding: spacing(4);
    }
  }

  &__title {

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
      @mixin breakpoint $breakpointTabletLandscape {
        color: $colorWhite;
      }
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
      @mixin breakpoint $breakpointTabletLandscape {
        color: $colorWhite;
      }
    }
  }

  &__filters {

    &--select {
      display: block;
      text-align: center;
      line-height:1;
      padding-bottom: spacing(4);

      @mixin breakpoint $breakpointTabletLandscape {
        display: none;
      }
    }

    &--list {
      display: none;

      @mixin breakpoint $breakpointTabletLandscape {
        display: block;
        padding-bottom: spacing(2);
      }
    }
  }

  &__filters__select-wrapper {
    display: inline-block;
    position: relative;
    width: spacing(32);
    overflow: hidden;
    border: 2px solid;
    border-radius: spacing(1);
    vertical-align: middle;

    [data-theme="carillon"] & {
      border-color: $carillonHippieBlue;
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      border-color: $waldhausYellowMetal;
      color: $waldhausDoveGrey;
    }
  }

  &__filters__select-caret {
    position: absolute;
    top: spacing(2);
    right: spacing(1);
    height: spacing(1);
    width: spacing(1);
    color: currentColor;
    margin-top: spacing(-0.5);
    pointer-events: none;
    line-height: 0;
  }

  &__filters__select {
    cursor: pointer;
    color: currentColor;
    background-color: transparent;
    height: spacing(4);
    line-height: spacing(4);
    border: none;
    width: 125%;
    appearance: none;
    -webkit-appearance: none;
    padding: 0 spacing(1);
  }

  &__filters__list {
    list-style-type: none;
    text-align: center;
    padding-top: spacing(2);
  }

  &__filters__item {
    display: inline-block;
  }

  &__filters__navigation__action {
    display: block;
    padding: spacing(1.7) spacing(1);
    color: $colorWhite;
    line-height: 2;

    &.is-active {
      [data-theme="carillon"] & {
        color: $carillonRiverBed;
        @mixin breakpoint $breakpointTabletLandscape {
          color: $colorWhite;
        }
      }

      [data-theme="waldhaus"] & {
        color: $waldhausYellowMetal;
        @mixin breakpoint $breakpointTabletLandscape {
          color: $colorWhite;
        }
      }
    }

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
      @mixin breakpoint $breakpointTabletLandscape {
        color: $colorWhite;
      }
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
      @mixin breakpoint $breakpointTabletLandscape {
        color: $colorWhite;
      }
    }
  }

  &__filters__action__title {}

  &__content {
    opacity: 1;
    will-change: opacity;
    transition-property: opacity;
    transition-duration: resolve($transitionDuration * 4)ms;
    transition-timing-function: $transitionEaseOutExpo;

    &.is-loading {
      transition-duration: resolve($transitionDuration * 1)ms;
      opacity: 0.25;
    }
  }

  &__cards {
    text-align: left;
    white-space: nowrap;
    @mixin breakpoint $breakpointTablet {
      font-size: 0;
      margin-left: spacing(-2);
      margin-right: spacing(-2);
    }
  }

  &__card {
    width: 300px;
    margin: 0 spacing(1);
    display: inline-block;
    white-space: normal;

    @mixin breakpoint $breakpointMobile {
      width: 320px;
    }

    @mixin breakpoint $breakpointTablet {
      margin: 0 spacing(2);
      width: 400px;
    }
  }

  &__no-results {
    padding: spacing(4);
  }

  &__no-results__title {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }
}
