$component: .horizontal-line-divider;
$component  {
  position: relative;

  &--is-padded {
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-bottom: spacing(10);
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: $colorOffWhite;
  }
}
