$component: .local-weather;
$component {

  &__frame {}

  &__container {}

  &__title {
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__list {
    border-top: 1px solid rgba($colorBlack, 0.05);
    @mixin breakpoint $breakpointTablet {
      border-top: none;
      display: flex;
    }
  }

  &__item {
    display: flex;
    padding: spacing(0.75) 0;
    border-bottom: 1px solid rgba($colorBlack, 0.05);
    @mixin breakpoint $breakpointTablet {
      border-bottom: none;
      flex-direction: column;
      flex: 1;
      max-width: 100px;
    }
  }

  &__item__header {
    padding-bottom: spacing(1);
  }

  &__item__icon {
    width: spacing(5);
    margin: 0 auto;
    text-align: center;
  }

  &__item__icon-background {
    width: spacing(4);
    height: spacing(4);
    border-radius: 100%;
    opacity: 0.5;
    background-color: $colorOffWhite;
    display: inline-block;
    padding: spacing(0.8);
  }

  &__item__body {
    display: flex;
    align-items: center;
    flex: 1;
    @mixin breakpoint $breakpointTablet {
      padding: spacing(2) spacing(1);
      flex-direction: column;
      border-right: 1px solid rgba($colorBlack, 0.05);
    }
  }

  &__item__title {
    @mixin breakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
  }

  &__item__value {
    @mixin breakpoint $breakpointTablet {
      text-align: center;
    }
  }

  &__item__title,
  &__item__value {
    flex: 1;
    text-align: center;

    @mixin breakpoint $containerPhablet {
      flex: 0;
    }
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }
}
