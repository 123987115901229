$component: .spread-block;
$component  {

  &--reverse {

    $(component)__container {
      @mixin breakpoint $breakpointTabletLandscape {
        flex-direction: row-reverse;
      }
    }

    $(component)__section {
      &--right {
        @mixin breakpoint $breakpointTabletLandscape {
          padding-left: 0;
          padding-right: spacing(4);
        }
        @mixin breakpoint $breakpointDesktop {
          padding-left: 0;
          padding-right: spacing(8);
        }
        @mixin breakpoint $breakpointDesktopLarge {
          padding-left: 0;
          padding-right: spacing(10);
        }
      }
    }
  }

  &__frame {
    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-bottom: spacing(10);
    }

    @mixin breakpoint $breakpointDesktopXLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
    }
  }

  &__container {
    margin: 0 auto;
    @mixin breakpoint $breakpointTabletLandscape {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    @mixin breakpoint $breakpointDesktopXLarge {
      max-width: $containerXxlarge;
    }
  }

  &__section {
    width: 100%;
    align-self: flex-start;

    &--left {
      @mixin breakpoint $breakpointTabletLandscape {
        width: 50%;
        width: calc(50% + spacing(4));
      }
      @mixin breakpoint $breakpointDesktop {
        width: calc(50% + spacing(8));
      }
      @mixin breakpoint $breakpointDesktopLarge {
        width: calc(50% + spacing(10));
      }
    }

    &--right {
      @mixin breakpoint $breakpointTabletLandscape {
        width: 50%;
        width: calc(50% - spacing(4));
        padding-left: spacing(4);
      }
      @mixin breakpoint $breakpointDesktop {
        width: calc(50% - spacing(8));
        padding-left: spacing(8);
      }
      @mixin breakpoint $breakpointDesktopLarge {
        width: calc(50% - spacing(10));
        padding-left: spacing(10);
      }
    }
  }
}
