$component: .feature-carousel;
$component  {
  position: relative;

  &__holder {
    padding-bottom: spacing(2);
  }

  &__carousel {
    width: 100%;
    white-space: nowrap;
    &:focus {
      box-shadow: 0 0 22px $carillonHippieBlue;
    }
  }
  .flickity-viewport {
    /* Note that overflow: hidden is not used because of siteimproves ADA requirements */
    overflow: auto;
    height: 100%;
    @mixin hide-scroll-bars;
  }

  &__card {
    width: 300px;
    margin: 0 spacing(1);
    display: inline-block;
    white-space: normal;

    @mixin breakpoint $breakpointMobile {
      width: 320px;
    }

    @mixin breakpoint $breakpointTablet {
      margin: 0 spacing(2);
      width: 400px;
    }

    @mixin breakpoint $breakpointTabletLandscape {
      width: 800px;
    }
  }

  &__controls {
    text-align: center;
  }

  &__dots {
    padding: spacing(2);
    display: inline-block;
    line-height: 1;
  }

  &__dot {
    display: inline-block;
    border: none;
    border-radius: 100%;
    opacity: 0.25;
    cursor: pointer;
    width: spacing(1);
    height: spacing(1);
    padding: 0;
    -webkit-appearance: none;
    margin: 0 2px;

    [data-theme="carillon"] & {
      background-color: $carillonCasper;
    }

    [data-theme="waldhaus"] & {
      background-color: $waldhausYellowMetal;
    }

    &.is-active {
      opacity: 1;
    }
  }

  &__frame {
    padding-left: spacing(2.5);
    padding-right: spacing(2.5);
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointTabletLandscape {
      padding-left: spacing(4);
      padding-right: spacing(4);
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-left: spacing(8);
      padding-right: spacing(8);
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
      padding-bottom: spacing(10);
    }
  }

  &__container {
    margin: 0 auto;
    overflow: hidden;
    max-width: $containerMedium;
    position: relative;
    text-align: center;
  }

  &__title,
  &__subtitle {
    padding-bottom: spacing(2);
    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(4);
    }
  }

  &__title {
    [data-theme="carillon"] & {
      color: $carillonHippieBlue;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__subtitle {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__ctas {
    padding-bottom: spacing(0.25);
  }

  &__cta {
    display: inline-block;
    @mixin uptoBreakpoint $breakpointMobile {
      width: 100%;
    }
  }
}
