$component: .experience-card;
$component  {
  position: relative;
  text-align: left;

  &__frame {
    display: block;
    @mixin uptoBreakpoint $breakpointTablet {
      padding-bottom: spacing(1);
    }
  }

  &__container {
    padding-bottom: aspect-ratio(440,680);
    position: relative;
    overflow: hidden;

    $(component)--portrait & {
      @mixin breakpoint $breakpointTabletLandscape {
        padding-bottom: aspect-ratio(550,440);
      }
    }

    $(component)--portrait-fixed & {
      padding-bottom: aspect-ratio(550,440);
    }
  }

  &__background {
    @mixin fill-parent;
  }

  &__background__media {
    @mixin fill-parent;
  }

  &__gradient {
    @mixin fill-parent;
    background-image: linear-gradient(transparent 0%, transparent 50%, rgba($colorOffBlack, 0.5) 75%, rgba($colorOffBlack, 0.5) 100%);
  }

  &__foreground {
    @mixin fill-parent;
  }

  &__foreground__frame {
    padding: spacing(2);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-end;

    @mixin breakpoint $breakpointTablet {
      justify-content: flex-start;
    }
  }

  &__foreground__title {
    color: $colorWhite;
    margin-right: spacing(0.5);
    text-align: center;

    @mixin breakpoint $breakpointTablet {
      text-align: left;
    }
  }

  &__foreground__title,
  &__foreground__icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__foreground__icon {
    width: spacing(2);
    opacity: 0;
    line-height: 0;
    will-change: opacity;
    transition-property: opacity;
    transition-duration: resolve($transitionDuration * 2)ms;
    transition-timing-function: $transitionEaseOutExpo;
    position: relative;
    top: -1px;
    display: none;

    @mixin breakpoint $breakpointTablet {
      display: inline-block;
    }

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }
    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }
}

.is-touch-device {
  $(component)__foreground__icon {
    display: none;
  }
}

.not-touch-device  {
  $(component) {
    &:hover {

      $(component)__foreground__icon {
        opacity: 1;
      }
    }
  }
}
