$component: .review-carousel;
$component  {

  &__frame {
    padding-left: spacing(2.5);
    padding-right: spacing(2.5);
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointTabletLandscape {
      padding-left: spacing(4);
      padding-right: spacing(4);
      padding-bottom: spacing(4);
    }

    @mixin breakpoint $breakpointDesktop {
      padding-left: spacing(8);
      padding-right: spacing(8);
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-left: spacing(10);
      padding-right: spacing(10);
      padding-bottom: spacing(10);
    }
  }

  &__container {
    margin: 0 auto;
    max-width: $containerPhablet;
    position: relative;
    text-align: center;
  }

  &__carousel {
    margin: 0 auto;
    overflow: hidden;
    max-width: $containerPhablet;
    width: 100%;
    text-align: center;
    &:focus {
      box-shadow: 0 0 22px $carillonHippieBlue;
    }
 }

  &__title {
    padding-bottom: spacing(2);
    display: inline-block;

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__review {
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  &__body {
    width: 100%;
    padding-bottom: spacing(2);

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__attribution {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__controls {
    text-align: center;
  }

  &__dots {
    padding: spacing(2);
    display: inline-block;
    line-height: 1;
  }

  &__dot {
    display: inline-block;
    border: none;
    border-radius: 100%;
    opacity: 0.25;
    cursor: pointer;
    width: spacing(1);
    height: spacing(1);
    padding: 0;
    -webkit-appearance: none;
    margin: 0 2px;

    [data-theme="carillon"] & {
      background-color: $carillonCasper;
    }

    [data-theme="waldhaus"] & {
      background-color: $waldhausYellowMetal;
    }

    &.is-active {
      opacity: 1;
    }
  }
}
