$component: .feature-card;
$component  {
  position: relative;

  &__frame {}

  &__container {
    position: relative;
    display: block;
  }

  &__aspect {
    position: relative;
    padding-bottom: aspect-ratio(1,1);
    overflow: hidden;
    height: 0;

    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: aspect-ratio(1,2);
    }
  }

  &__background {
    @mixin fill-parent;
  }

  &__media {
    @mixin fill-parent;
  }

  &__foreground {
    @mixin fill-parent;
    background-color: rgba($colorBlack, 0.65);
  }

  &__foreground__frame {
    position: absolute;
    padding: spacing(2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  &__content {
    position: relative;
    color: $colorWhite;
    text-align: center;
    width: 100%;
  }

  &__title {}

  &__cta {
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: spacing(-4);
    width: 100%;
  }
}
