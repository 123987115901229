$component: .experiences-slider;
$component  {
  text-align: left;
  white-space: nowrap;
  &:focus {
    box-shadow: 0 0 22px $carillonHippieBlue;
  }
  @mixin breakpoint $breakpointTablet {
    font-size: 0;
    margin-left: spacing(-2);
    margin-right: spacing(-2);
  }

  .flickity-viewport {
    /* Note that overflow: hidden is not used because of siteimproves ADA requirements */
    overflow: auto;
    height: 100%;
    @mixin hide-scroll-bars;
  }

  &__card {
    width: 300px;
    margin: 0 spacing(1);
    display: inline-block;
    white-space: normal;

    @mixin breakpoint $breakpointMobile {
      width: 320px;
    }

    @mixin breakpoint $breakpointTablet {
      margin: 0 spacing(2);
      width: 400px;
    }
  }
}
