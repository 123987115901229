$component: .billboard;
$component  {
  overflow: hidden;
  position: relative;

  &__frame {
    padding-bottom: spacing(5);

    @mixin breakpoint $breakpointDesktop {
      padding-bottom: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      padding-bottom: spacing(10);
    }
  }

  &__container {
    height: 100vh;
    min-height: $minBillboardHeight;
  }

  /*
  this modifiers is used primarily on detail pages
  this will simply add a max height from the
  */
  &--is-fixed-height {
    $(component)__container {
      max-height: $maxBillboardHeightMobile;
    }

    $(component)__gradient__arrow {
      position: relative;
      bottom: spacing(-8);
    }

    $(component)__gradient {
      background-image: linear-gradient(transparent 0%, rgba($colorWhite, 0) 50%, rgba($colorWhite, 0) 60%, rgba($colorWhite, 0.1) 70%, rgba($colorWhite, 1) 90%);
      @mixin breakpoint $breakpointTablet {
        min-height: unset;
      }
    }
  }

  &--has-sub-navigtion {
    $(component)__container {
      min-height: $maxBillboardHeightMobile;
      height: calc(100vh - spacing(10));
      @mixin breakpoint $breakpointTablet {
        height: calc(100vh - spacing(13));
      }
      @mixin breakpoint $breakpointTabletLandscape {
        height: calc(100vh - spacing(16));
      }
    }
  }

  &--fade-headline {
    $(component)__foreground__title {
      opacity: 0;
    }
  }

  &__container {
    position: relative;
    height: 100vh;
    min-height: $minBillboardHeight;
    background-color: $colorWhite;
  }

  &__background {
    @mixin fill-parent;
  }

  &__background__media {
    @mixin fill-parent;
  }

  &__blend {
    @mixin fill-parent;
    background-color: rgba($colorBlack, 0.25);
  }

  &__gradient {
    @mixin fill-parent;
    background-image: linear-gradient(transparent 0%, rgba($colorWhite, 0) 50%, rgba($colorWhite, 0) 60%, rgba($colorWhite, 0.1) 70%, rgba($colorWhite, 1) 100%);
    padding: spacing(2.5);
    padding-bottom: $offsetMobileActions;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @mixin breakpoint $breakpointTablet {
      min-height: 800px;
      padding: spacing(4);
      padding-bottom: resolve(spacing(4) + $offsetContactBar);
    }
  }

  &__gradient__arrow {
    width: spacing(3);

    [data-theme="carillon"] &  {
      color: $carillonHippieBlue;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }

    &--mobile {
      width: spacing(3);
      display: block;
      @mixin breakpoint $breakpointTabletLandscape {
        display: none;
      }
    }

    &--not-mobile {
      display: none;
      @mixin breakpoint $breakpointTabletLandscape {
        display: block;
      }
    }
  }

  &__gradient-bottom-edge {
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    position: absolute;
    width: 100%;
    left: 0;
    height: spacing(5);

    @mixin breakpoint $breakpointDesktop {
      height: spacing(8);
    }

    @mixin breakpoint $breakpointDesktopLarge {
      height: spacing(10);
    }
  }

  &__foreground {
    @mixin fill-parent;
  }

  &__foreground__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing(2.5);
    height: 100%;
    text-align: center;
    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }
  }

  &__foreground__logo {
    width: 100%;
    max-width: $containerXxxsmall;
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-80px);
    @mixin breakpoint $breakpointTabletLandscape {
      display: none;
    }

    &--waldhaus {
      max-width: spacing(22);
      transform: translateX(-50%) translateY(-110px);
      @mixin breakpoint $breakpointTabletLandscape {
        transform: translateX(-50%) translateY(-130px);
        max-width: spacing(25);
        display: block;
      }
      @mixin breakpoint $breakpointDesktopLarge {
        display: none;
      }
    }
  }

  &__foreground__headline {
    text-align: center;
    color: $colorWhite;
    position: relative;
    width: 100%;

    @mixin uptoBreakpoint $breakpointTablet {

      br {
        display: none;
      }
    }

    @mixin breakpoint $breakpointTablet {
      max-width: $containerHeadline;
    }
  }

  &__foreground__title {
    opacity: 1;
    transition-property: opacity;
    transition-duration: resolve($transitionDuration * 20)ms;
    transition-timing-function: $transitionEaseOutCirc;
    transition-delay: 650ms;
  }

  &__foreground__multiline-headline {
    position: relative;
    min-height: 100px;
  }

  &__foreground__multiline-headline-step {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__foreground__multiline-headline-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    opacity: 0;

    &.is-animated {
      transition-duration: resolve($transitionDuration * 1)ms;
      transition-timing-function: $transitionEaseOutExpo;
      opacity: 0;
    }

    .is-active & {
      transition-duration: 0;
      transform: translateY(0px);
      opacity: 1;

      &.is-animated {
        transition-timing-function: transitionEaseOutCubic;
        transition-duration: resolve($transitionDuration * 5)ms;
      }
    }
  }

  &__foreground__multiline-headline-subtitle {
    display: block;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;

    &.is-animated {
      transition-duration: resolve($transitionDuration * 1)ms;
      transition-timing-function: $transitionEaseOutExpo;
      transform: translateY(10px);
      opacity: 0;
    }

    .is-active & {
      transition-duration: 0;
      transform: translateY(0px);
      opacity: 1;

      &.is-animated {
        transition-delay: 150ms;
        transition-timing-function: transitionEaseOutCubic;
        transition-duration: resolve($transitionDuration * 8)ms;
      }
    }
  }

  &__chalet-belmonet {
    line-height: 1;
    max-width: 300px;
    display: inline-block;
    width: 100%;
    padding-bottom: spacing(2);
    @mixin breakpoint $breakpointTablet {
      max-width: 500px;
    }
  }
}
