$component: .spread-block-section;
$component  {

  &__container {

    &--reverse {
      @mixin breakpoint $breakpointTabletLandscape {
        display: flex;
        flex-direction: column-reverse;
      }

      $(component)__content {

        @mixin breakpoint $breakpointTablet {
          &:last-child {
            padding-bottom: spacing(4);
          }
        }
        @mixin breakpoint $breakpointTabletLandscape {
          &:last-child {
            padding-bottom: spacing(4);
          }
        }
        @mixin breakpoint $breakpointDesktop {
          &:last-child {
            padding-bottom: spacing(8);
          }
        }
        @mixin breakpoint $breakpointDesktopLarge {
          &:last-child {
            padding-bottom: spacing(10);
          }
        }
      }
    }
  }

  &__media {
    margin-bottom: spacing(1);
    display: block;
    position: relative;
    @mixin breakpoint $breakpointTabletLandscape {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &__media-link {
    display: block;
  }

  &--left--default,
  &--left--reverse {
    $(component)__content {

      @mixin uptoBreakpoint $breakpointTabletLandscape {
        padding-top: 0;
      }
    }
  }

  &--right--default {
    $(component)__content {
      @mixin breakpoint $breakpointTabletLandscape {
        padding: spacing(4);
        padding-right: spacing(4);
        padding-left: 0;
      }
      @mixin breakpoint $breakpointDesktop {
        padding: spacing(8);
        padding-right: spacing(8);
        padding-left: 0;
      }
      @mixin breakpoint $breakpointDesktopLarge {
        padding: spacing(10);
        padding-right: spacing(10);
        padding-left: 0;
      }
    }
  }

  &--right {
    $(component)__media {
      padding-bottom: 0;
    }
  }

  &--left--reverse {
    $(component)__content {
      @mixin breakpoint $breakpointTabletLandscape {
        padding-left: resolve(spacing(4) * 2);
      }
      @mixin breakpoint $breakpointDesktop {
        padding-left: resolve(spacing(8) * 2);
      }
      @mixin breakpoint $breakpointDesktopLarge {
        padding-left: resolve(spacing(10) * 2);
      }
    }
  }

  &__content {
    padding: spacing(4) spacing(2.5);

    @mixin breakpoint $breakpointTablet {
      padding: spacing(4);
    }
    @mixin breakpoint $breakpointTabletLandscape {
      padding: spacing(4);
      &:last-child {
        padding-bottom: 0;
      }
    }
    @mixin breakpoint $breakpointDesktop {
      padding: spacing(8);
      &:last-child {
        padding-bottom: 0;
      }
    }
    @mixin breakpoint $breakpointDesktopLarge {
      padding: spacing(10);
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &--right--reverse {
    $(component)__content {
      @mixin breakpoint $breakpointTabletLandscape {
        padding-right: 0;
        padding-bottom: spacing(4);
      }

      @mixin breakpoint $breakpointDesktop {
        padding-bottom: spacing(8);
      }

      @mixin breakpoint $breakpointDesktopLarge {
        padding-bottom: spacing(10);
      }
    }
  }

  &__title {
    &:not(:last-child) {
      padding-bottom: spacing(2);
    }

    [data-theme="carillon"] & {
      color: $carillonRiverBed;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausYellowMetal;
    }
  }

  &__subtitle {
    &:not(:last-child) {
      padding-bottom: spacing(1);
    }
    max-width: $containerMedium;

    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
    .type--h4 {
      line-height: 1.5;
    }
  }

  &__body {
    [data-theme="carillon"] & {
      color: $carillonDoveGrey;
    }

    [data-theme="waldhaus"] & {
      color: $waldhausDoveGrey;
    }
  }

  &__body,
  &__ruled_specs,
  &__local-weather {
    &:not(:last-child) {
      padding-bottom: spacing(3);
      @mixin breakpoint $breakpointTablet {
        padding-bottom: spacing(4);
      }
    }
    @mixin breakpoint $breakpointTablet {

      padding-right: 20%;
    }
    @mixin breakpoint $breakpointTabletLandscape {
      padding-right: spacing(4);
    }
    @mixin breakpoint $breakpointTabletLandscape {
      padding-right: 0;
    }
  }

  &__ctas {
    margin-left: spacing(-0.5);
    margin-right: spacing(-0.5);
  }

  &__cta {
    display: inline-block;
    padding: 0 spacing(0.5) 0 spacing(0.5);
    &:not(:last-child) {
      padding-bottom: spacing(1);
    }
    @mixin uptoBreakpoint $breakpointMobile {
      width: 100%;
    }
  }
}
